
.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 55px;
}

.hamRotate.active {
    transform: rotate(45deg);
}
.hamRotate180.active {
    transform: rotate(180deg);
}
.line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:#364A63;
    stroke-width:5.5;
    stroke-linecap:round;
}

.ham4 .top {
    stroke-dasharray: 40 121;
}
.ham4 .bottom {
    stroke-dasharray: 40 121;
}
.ham4.active .top {
    stroke-dashoffset: -68px;
}
.ham4.active .bottom {
    stroke-dashoffset: -68px;
}


